import { PagableSincerityResults, SincerityApi, SincerityResult } from "../net";
import { RootState } from "./store";
import { sliceWithThunks } from "./utils";

interface SincerityState {
  isLoading: boolean;
  results: SincerityResult[];
  demoItems: SincerityResult[];
  cursor?: string;
  noResults: boolean;
  resultsSucceeded: boolean;
  resultsFailed?: string;
  numUploadsThisMonth: number;
  prevPageLastCreatedAt?: string;
}

const initialState: SincerityState = {
  isLoading: false,
  results: [],
  demoItems: [],
  noResults: false,
  cursor: undefined,
  resultsSucceeded: false,
  resultsFailed: undefined,
  numUploadsThisMonth: 0,
  prevPageLastCreatedAt: undefined,
};

export const sinceritySlice = sliceWithThunks({
  name: "sincerity",
  initialState,
  reducers: (create) => ({
    reset: create.reducer(() => {
      return initialState;
    }),
    deleteItem: create.reducer<{ srcID: string }>((state, action) => {
      const srcID = action.payload.srcID;
      state.results = state.results.filter((item) => item.srcID !== srcID); // INFO: This is a reducer that removes an item from the results array. This is used in the SincerityList component.
    }),
    _getResults: create.asyncThunk(
      async (payload, thunkApi) => {
        const state = (thunkApi.getState() as { sincerity: SincerityState; })
          .sincerity;
        var retryCount = 0;
        var sleepTime = 1000;
        while (retryCount < 5) {
          try {
            const result = await new SincerityApi().getResults({
              size: 12,
              includeDemoVideos: true,
              prevPageLastCreatedAt: state.cursor ? state.prevPageLastCreatedAt : undefined, // INFO: The redux state variable 'prevPageLastCreatedAt' saves the value of the last result of a given page, so every subsequent call will send this value to the API to get the next page of results.
            });
            return result;
          } catch (e) {
            if (retryCount < 5) {
              await new Promise(resolve => setTimeout(resolve, sleepTime));
              sleepTime += 1000;
              retryCount += 1;
              if (retryCount == 5) {
                retryCount = 0;
                return thunkApi.rejectWithValue("Server may be busy. Please try again after sometime!");
              }
            }
          }
        }
      },
      {
        pending: (state) => {
          state.isLoading = true;
        },
        settled: (state) => {
          state.isLoading = false;
        },
        fulfilled: (state, action) => {
          const data = action.payload as unknown as PagableSincerityResults;
          state.results.push(...data.items);
          state.cursor = data.cursor === null ? undefined : data.cursor;
          if (state.demoItems.length === 0) {
            state.demoItems.push(...data.demoItems); // INFO: We do this to avoid appending the same demo items over and over again to the demoItems array.
          }
          state.noResults = !state.cursor;
          state.resultsSucceeded = true;
          state.numUploadsThisMonth = data.numUploadsThisMonth;
          if (state.cursor) {
            state.prevPageLastCreatedAt = new Date(data.items[data.items.length - 1]["createdAt"]).getTime().toString();
          }
          else {
            state.prevPageLastCreatedAt = undefined;
          }
        },
        rejected: (state, action) => {
          state.resultsFailed = action.payload as string;
        },
      }
    ),
    get getResults() {
      return this._getResults;
    },
    set getResults(value) {
      this._getResults = value;
    },
  }),
  selectors: {
    selectLoading: (sliceState) => sliceState.isLoading,
    selectResults: (sliceState) => sliceState.results,
    selectDemoItems: (sliceState) => sliceState.demoItems,
    selectNoResults: (sliceState) => sliceState.noResults,
    selectResultsSucceeded: (sliceState) => sliceState.resultsSucceeded,
    selectResultsFailed: (sliceState) => sliceState.resultsFailed,
    selectNumUploadsThisMonth: (sliceState) => sliceState.numUploadsThisMonth,
  },
});

export const {
  selectLoading,
  selectResults,
  selectDemoItems,
  selectNoResults,
  selectResultsSucceeded,
  selectResultsFailed,
  selectNumUploadsThisMonth,
} = sinceritySlice.getSelectors((rootState: RootState) => rootState.sincerity);

export const { getResults, reset, deleteItem } = sinceritySlice.actions;
