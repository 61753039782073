import { RootState } from "./store";
import { sliceWithThunks } from "./utils";
import { UserProfileApi } from "../net/apis/UserProfileApi";

export interface UserProfileEntry {
  userId?: string;       
  isPrivileged: boolean; 
  isFetching: boolean;    
  noResults: boolean;    
  resultsSucceeded: boolean;
  resultsFailed?: string;
}

const initialState: UserProfileEntry = {
  userId: undefined,
  isPrivileged: false,     
  isFetching: false,        
  noResults: false,        
  resultsSucceeded: false, 
  resultsFailed: undefined 
};

export const UserProfileSlice = sliceWithThunks({
    name: "userProfile",
    initialState,
    reducers: (create) => ({
      reset: create.reducer(() => {
        return initialState;
      }),
      _getUserProfile: create.asyncThunk(
        async (payload, thunkApi) => {
          const state = (thunkApi.getState() as { userProfile: UserProfileEntry });
          let retryCount = 0;
          let sleepTime = 1000;
          while (retryCount < 5) {
            try {
              const result = await new UserProfileApi().getUserProfile({
              });
              return result;
            } catch (e) {
              if (retryCount < 5) {
                await new Promise(resolve => setTimeout(resolve, sleepTime));
                sleepTime += 1000;
                retryCount += 1;
                if (retryCount === 5) {
                  return thunkApi.rejectWithValue("Server may be busy. Please try again later.");
                }
              }
            }
          }
        },
        {
          pending: (state) => {
            state.isFetching = true;
          },
          settled: (state) => {
            state.isFetching = false;
          },
          fulfilled: (state, action) => {
            const data = action.payload;
            sessionStorage.setItem("privileged_user", data? String(data.isPrivileged) : "false");
            state.resultsSucceeded = true;
          },
          rejected: (state, action) => {
            state.resultsFailed = action.payload as string;
          },
        }
      ),
      get getUserProfile() {
        return this._getUserProfile;
      },
      set getUserProfile(value) {
        this._getUserProfile = value;
      },
    }),
    selectors: {
      selectFetching: (sliceState) => sliceState.isFetching,
      selectNoResults: (sliceState) => sliceState.noResults,
      selectResultsSucceeded: (sliceState) => sliceState.resultsSucceeded,
      selectResultsFailed: (sliceState) => sliceState.resultsFailed,
    },
  });
  export const {
    selectFetching,
  } = UserProfileSlice.getSelectors((rootState: RootState) => rootState.UserProfile);

  export const { getUserProfile, reset } = UserProfileSlice.actions;
